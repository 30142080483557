import React from "react";
import Typography from "@material-ui/core/Typography";
import styles from "./Projects.module.scss";

export default () => {
  return (
    <div className={styles.root}>
      <Typography variant="h1">最近の活動</Typography>

      <div className={styles.lead}>
        <Typography>
          プロジェクトごとにゆっくり活動中です。
          <br />
          コアメンバーのみでSlack内にて情報共有中。
        </Typography>
      </div>

      <Typography variant="h2">プロジェクト一覧</Typography>

      <div className={styles.groupContainer}>
        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01T5FVH1RB"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              みさわや整備
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              掃除をしたり、草刈りしたり、箕澤屋を長ーく維持ためのメンテナンス活動です。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01T8MBTUR0"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              屋根裏トーク
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              アヤナとコバダイがリモートで雑談する
              <a
                href="https://www.youtube.com/playlist?list=PLpFjbwWOasbHxaFaGJ61ytnLcLTfAqacp"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                YouTubeラジオ
              </a>
              です。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01STQUTUK1"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              ターシャガーデン
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>箕澤屋の庭をターシャの庭のように、という理想を掲げて動き中。</p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01T8M1MY2W"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              ヨガライフ
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              古民家で楽しむヨガライフをテーマに、和室からヨガ映像を発信したり、朝ヨガをやったり。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01STTPN3D5"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              山ガク部
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              気軽なアウトドア体験をしたい人が集まって、箕澤屋を集合場所に外遊びを楽しむ活動。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01TSDL6N65"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              食と農園
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              野菜づくりや農園体験、グルテンフリー、ジャムづくり、ピザ焼き、クラフトビールなど、食に関することいろいろ。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01STV938BZ"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              フィルム部
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              自分たちが撮りたいものを撮って作品にしてしまう、クリエイティビティを刺激するための集まり。
            </p>
          </div>
        </div>

        <div className={styles.groupItem}>
          <Typography variant="h3">
            #
            <a
              href="https://app.slack.com/client/T01T8QECA4B/C01T3UL5A5U"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              ニューヨーク支部
            </a>
          </Typography>
          <div className={styles.groupBox}>
            <p>
              代表アヤナがNYにいながらやりたいことと箕澤屋とつなぎたいという欲望を探る活動です。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
