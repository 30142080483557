import React from "react";
import Layout from "../../components/member/Layout";
import Projects from "../../components/member/Projects/Projects";

export default () => {
  const pageSlug = "projects";
  const pageTitle = "お知らせ";
  return (
    <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
      <Projects />
    </Layout>
  );
};
